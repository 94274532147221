.lst-kix_list_1-3 > li:before {
  content: "\002022   "
}

.lst-kix_list_1-4 > li:before {
  content: "\002022   "
}

ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0
}

.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0
}

ol.lst-kix_list_1-0 {
  list-style-type: none
}

ol.lst-kix_list_1-1 {
  list-style-type: none
}

ol.lst-kix_list_1-2 {
  list-style-type: none
}

.lst-kix_list_1-7 > li:before {
  content: "\002022   "
}

.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1
}

.lst-kix_list_1-5 > li:before {
  content: "\002022   "
}

.lst-kix_list_1-6 > li:before {
  content: "\002022   "
}

.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2
}

ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0
}

ul.lst-kix_list_1-3 {
  list-style-type: none
}

.lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) " "
}

ul.lst-kix_list_1-4 {
  list-style-type: none
}

.lst-kix_list_1-8 > li:before {
  content: "\002022   "
}

ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0
}

ul.lst-kix_list_1-7 {
  list-style-type: none
}

.lst-kix_list_1-1 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) ". "
}

.lst-kix_list_1-2 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) ". "
}

ul.lst-kix_list_1-8 {
  list-style-type: none
}

ul.lst-kix_list_1-5 {
  list-style-type: none
}

ul.lst-kix_list_1-6 {
  list-style-type: none
}

ol {
  margin: 0;
  padding: 0
}

table td, table th {
  padding: 0
}

.c10 {
  -webkit-text-decoration-skip: none;
  color: #0000ff;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;

  font-style: normal
}

.c2 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;

  font-style: normal
}

.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;

  font-style: normal
}

.c12 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-indent: 21.3pt;
  text-align: left
}

.c7 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-indent: 21.3pt;
  text-align: center
}

.c0 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-indent: 21.3pt;
  text-align: justify
}

.c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-indent: 21.2pt;
  text-align: center
}

.c5 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  text-align: justify
}

.c6 {
  max-width: 481.6pt;
  padding: 20px
}

.c9 {
  color: inherit;
  text-decoration: inherit
}

.c8 {
  text-indent: 35.5pt
}

.c4 {
  background-color: #ffffff
}

.c3 {
  height: 11pt
}

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;

  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  line-height: 1.0;
  page-break-after: avoid;
  font-style: italic;
  text-align: left
}

li {
  color: #000000;
  font-size: 11pt;
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
}

h1 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 0pt;

  line-height: 1.1458333333333333;
  text-align: justify
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;

  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}

h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;

  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}

h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;

  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}

h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;

  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;

  line-height: 1.0;
  page-break-after: avoid;
  text-align: left
}
