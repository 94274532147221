.c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;

    font-style: normal
}

.c6 {
    padding-top: 0pt;
    text-indent: 21.3pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: center
}

.c8 {
    color: #4472c4;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;

    font-style: normal
}

.c0 {
    padding-top: 0pt;
    text-indent: 21.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;

    font-style: normal
}

.c2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt
}

.c4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c10 {
    background-color: #ffffff;
    max-width: 467.7pt;
    padding: 20px
}

.c9 {
    font-weight: 400;
}

.c7 {
    font-weight: 700;
}

.c3 {
    height: 11pt
}
