.lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1
}

ol.lst-kix_list_3-1 {
    list-style-type: none
}

ol.lst-kix_list_3-2 {
    list-style-type: none
}

.lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1
}

ol.lst-kix_list_3-0 {
    list-style-type: none
}

.lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1
}

.lst-kix_list_3-0 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) " "
}

ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0
}

.lst-kix_list_3-1 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) "." counter(lst-ctn-kix_list_3-1, decimal) " "
}

.lst-kix_list_3-2 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) "." counter(lst-ctn-kix_list_3-1, decimal) "." counter(lst-ctn-kix_list_3-2, decimal) ". "
}

.lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0
}

ul.lst-kix_list_1-3 {
    list-style-type: none
}

.lst-kix_list_3-5 > li:before {
    content: "\002022   "
}

ul.lst-kix_list_1-4 {
    list-style-type: none
}

.lst-kix_list_3-4 > li:before {
    content: "\002022   "
}

ul.lst-kix_list_1-7 {
    list-style-type: none
}

.lst-kix_list_3-3 > li:before {
    content: "\002022   "
}

ul.lst-kix_list_1-8 {
    list-style-type: none
}

ul.lst-kix_list_1-5 {
    list-style-type: none
}

ul.lst-kix_list_1-6 {
    list-style-type: none
}

.lst-kix_list_3-8 > li:before {
    content: "\002022   "
}

.lst-kix_list_3-6 > li:before {
    content: "\002022   "
}

.lst-kix_list_3-7 > li:before {
    content: "\002022   "
}

.lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2
}

ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0
}

.lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2
}

.lst-kix_list_4-8 > li:before {
    content: "\002022   "
}

.lst-kix_list_4-7 > li:before {
    content: "\002022   "
}

li.li-bullet-6:before {
    margin-left: -10.4pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 10.4pt
}

li.li-bullet-7:before {
    margin-left: -10.4pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 10.4pt
}

ul.lst-kix_list_4-8 {
    list-style-type: none
}

ul.lst-kix_list_4-6 {
    list-style-type: none
}

ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0
}

ul.lst-kix_list_4-7 {
    list-style-type: none
}

li.li-bullet-10:before {
    margin-left: -17.9pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 17.9pt
}

li.li-bullet-13:before {
    margin-left: -28.3pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 28.3pt
}

li.li-bullet-14:before {
    margin-left: -17.9pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 17.9pt
}

ul.lst-kix_list_4-4 {
    list-style-type: none
}

ul.lst-kix_list_4-5 {
    list-style-type: none
}

ul.lst-kix_list_4-3 {
    list-style-type: none
}

li.li-bullet-18:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0
}

.lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0
}

ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 10
}

li.li-bullet-2:before {
    margin-left: -21.1pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 21.1pt
}

ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0
}

.lst-kix_list_2-6 > li:before {
    content: "\002022   "
}

.lst-kix_list_2-7 > li:before {
    content: "\002022   "
}

ol.lst-kix_list_1-0 {
    list-style-type: none
}

.lst-kix_list_2-4 > li:before {
    content: "\002022   "
}

.lst-kix_list_2-5 > li:before {
    content: "\002022   "
}

.lst-kix_list_2-8 > li:before {
    content: "\002022   "
}

ol.lst-kix_list_1-1 {
    list-style-type: none
}

ol.lst-kix_list_1-2 {
    list-style-type: none
}

ul.lst-kix_list_3-7 {
    list-style-type: none
}

ul.lst-kix_list_3-8 {
    list-style-type: none
}

ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 9
}

ul.lst-kix_list_3-5 {
    list-style-type: none
}

ul.lst-kix_list_3-6 {
    list-style-type: none
}

ul.lst-kix_list_3-3 {
    list-style-type: none
}

ul.lst-kix_list_3-4 {
    list-style-type: none
}

li.li-bullet-19:before {
    margin-left: -28.1pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 28.1pt
}

li.li-bullet-17:before {
    margin-left: -17.9pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 17.9pt
}

li.li-bullet-1:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_list_4-0 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) " "
}

li.li-bullet-15:before {
    margin-left: -28.3pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 28.3pt
}

.lst-kix_list_4-1 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-1, decimal) " "
}

li.li-bullet-3:before {
    margin-left: -28.1pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 28.1pt
}

.lst-kix_list_4-4 > li:before {
    content: "\002022   "
}

.lst-kix_list_4-3 > li:before {
    content: "\002022   "
}

.lst-kix_list_4-5 > li:before {
    content: "\002022   "
}

.lst-kix_list_4-2 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-1, decimal) "." counter(lst-ctn-kix_list_4-2, decimal) ". "
}

.lst-kix_list_4-6 > li:before {
    content: "\002022   "
}

ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0
}

ol.lst-kix_list_4-0 {
    list-style-type: none
}

ol.lst-kix_list_4-1 {
    list-style-type: none
}

ol.lst-kix_list_4-2 {
    list-style-type: none
}

li.li-bullet-8:before {
    margin-left: -10.4pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 10.4pt
}

li.li-bullet-9:before {
    margin-left: -17.9pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 17.9pt
}

li.li-bullet-5:before {
    margin-left: -21.2pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 21.2pt
}

li.li-bullet-12:before {
    margin-left: -28.3pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 28.3pt
}

ul.lst-kix_list_2-8 {
    list-style-type: none
}

ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0
}

li.li-bullet-11:before {
    margin-left: -28.1pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 28.1pt
}

ul.lst-kix_list_2-2 {
    list-style-type: none
}

.lst-kix_list_1-0 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "
}

ul.lst-kix_list_2-3 {
    list-style-type: none
}

ul.lst-kix_list_2-0 {
    list-style-type: none
}

ul.lst-kix_list_2-1 {
    list-style-type: none
}

ul.lst-kix_list_2-6 {
    list-style-type: none
}

.lst-kix_list_1-1 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) ". "
}

.lst-kix_list_1-2 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) ". "
}

ul.lst-kix_list_2-7 {
    list-style-type: none
}

ul.lst-kix_list_2-4 {
    list-style-type: none
}

ul.lst-kix_list_2-5 {
    list-style-type: none
}

.lst-kix_list_1-3 > li:before {
    content: "\002022   "
}

.lst-kix_list_1-4 > li:before {
    content: "\002022   "
}

.lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0
}

li.li-bullet-16:before {
    margin-left: -28.3pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 28.3pt
}

.lst-kix_list_1-7 > li:before {
    content: "\002022   "
}

.lst-kix_list_1-5 > li:before {
    content: "\002022   "
}

.lst-kix_list_1-6 > li:before {
    content: "\002022   "
}

li.li-bullet-0:before {
    margin-left: -21.4pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 21.4pt
}

li.li-bullet-4:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_list_2-0 > li:before {
    content: "\0025cf   "
}

.lst-kix_list_2-1 > li:before {
    content: "\002022   "
}

.lst-kix_list_1-8 > li:before {
    content: "\002022   "
}

.lst-kix_list_2-2 > li:before {
    content: "\002022   "
}

.lst-kix_list_2-3 > li:before {
    content: "\002022   "
}

.lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2
}

ol {
    margin: 0;
    padding: 0
}

table td, table th {
    padding: 0
}

.c112 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 184.3pt;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c52 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 152.9pt;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c30 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 150.9pt;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c8 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 24pt;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c14 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 9pt;

    font-style: normal
}

.c7 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.7pt
}

.c66 {
    margin-left: 5.8pt;
    padding-top: 0.2pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.1pt
}

.c62 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.4pt
}

.c24 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 11.2pt
}

.c64 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.5pt
}

.c37 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.8pt
}

.c25 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify;
    margin-right: 8.7pt
}

.c68 {
    margin-left: 5.8pt;
    padding-top: 0.2pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 9.6pt
}

.c67 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify;
    margin-right: 8.8pt
}

.c45 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.8pt
}

.c10 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 7.8pt
}

.c35 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 9.7pt
}

.c44 {
    margin-left: 5.8pt;
    padding-top: 0.3pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify;
    margin-right: 8.8pt
}

.c41 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.2pt
}

.c27 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.4pt
}

.c6 {
    margin-left: 5.8pt;
    padding-top: 0.2pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 9.4pt
}

.c50 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.4pt
}

.c56 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.5pt
}

.c57 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.4pt
}

.c47 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.6pt
}

.c3 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.6pt
}

.c54 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.3pt
}

.c59 {
    margin-left: 5.8pt;
    padding-top: 0.4pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify;
    margin-right: 8.3pt
}

.c18 {
    margin-left: 5.8pt;
    padding-top: 0.2pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 7.9pt
}

.c9 {
    margin-left: 5.8pt;
    padding-top: 0.2pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 9.2pt
}

.c60 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 9pt
}

.c108 {
    -webkit-text-decoration-skip: none;
    color: #0462c1;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;

    font-style: normal
}

.c49 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.3pt
}

.c11 {
    margin-left: 5.8pt;
    padding-top: 0.3pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 9.7pt
}

.c53 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.2pt
}

.c12 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.1pt
}

.c13 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.1pt
}

.c151 {
    margin-left: 5.8pt;
    padding-top: 0.3pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.7pt
}

.c92 {
    margin-left: 5.8pt;
    padding-top: 0.6pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: left;
    margin-right: 8.8pt
}

.c140 {
    margin-left: 41.8pt;
    padding-top: 0.1pt;
    padding-left: -7.6pt;
    padding-bottom: 0pt;
    line-height: 0.9791666666666666;
    text-align: justify;
    margin-right: 8.7pt
}

.c85 {
    padding-top: 0pt;
    text-indent: 35.5pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c103 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.6pt
}

.c107 {
    margin-left: 5.8pt;
    padding-top: 0.5pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.9pt
}

.c79 {
    margin-left: 5.8pt;
    padding-top: 0.2pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.5pt
}

.c72 {
    margin-left: 41.8pt;
    padding-top: 0.3pt;
    padding-left: -7.6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.8pt
}

.c100 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 9.1pt
}

.c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;

    font-style: normal
}

.c120 {
    margin-left: 5.8pt;
    padding-top: 0.3pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 9.2pt
}

.c89 {
    margin-left: 5.8pt;
    padding-top: 0.2pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.3pt
}

.c51 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.7pt
}

.c99 {
    padding-top: 4.7pt;
    text-indent: -0.1pt;
    padding-bottom: 0pt;
    line-height: 0.9666666666666667;
    text-align: center;
}

.c139 {
    padding-top: 0.3pt;
    text-indent: 87.1pt;
    padding-bottom: 0pt;
    line-height: 2.0166666666666666;
    text-align: left;
}

.c78 {
    margin-left: 5.8pt;
    padding-top: 0.2pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify;
    margin-right: 9.4pt
}

.c118 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: left;
    margin-right: 9.2pt
}

.c82 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.6pt
}

.c63 {
    margin-left: 41.8pt;
    padding-top: 0.4pt;
    padding-left: -7.6pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.6pt
}

.c117 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.5pt
}

.c114 {
    margin-left: 5.8pt;
    padding-top: 0.3pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.6pt
}

.c88 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 9.4pt
}

.c29 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.1pt
}

.c113 {
    margin-left: 5.8pt;
    padding-top: 4.5pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 9.7pt
}

.c124 {
    margin-left: 5.8pt;
    padding-top: 0.3pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.2pt
}

.c136 {
    margin-left: 5.8pt;
    padding-top: 0.3pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify;
    margin-right: 8.4pt
}

.c104 {
    margin-left: 5.8pt;
    padding-top: 0.2pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.7pt
}

.c28 {
    margin-left: 52.1pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: left;
    margin-right: 27.1pt
}

.c130 {
    margin-left: 5.8pt;
    padding-top: 0.3pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.4pt
}

.c121 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 9.8pt
}

.c4 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;

    font-style: normal
}

.c91 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify;
    margin-right: 8.2pt
}

.c109 {
    margin-left: 5.8pt;
    padding-top: 0.2pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.7pt
}

.c152 {
    margin-left: 52.1pt;
    padding-top: 0.2pt;
    padding-left: 10.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c144 {
    margin-left: 52.1pt;
    padding-top: 0pt;
    padding-left: -0.1pt;
    padding-bottom: 0pt;
    line-height: 0.8833333333333333;
    text-align: justify
}

.c126 {
    margin-left: 52.1pt;
    padding-top: 0pt;
    padding-left: -0.1pt;
    padding-bottom: 0pt;
    line-height: 0.8791666666666668;
    text-align: justify
}

.c17 {
    margin-left: 52.1pt;
    padding-top: 0.3pt;
    padding-left: 10.3pt;
    padding-bottom: 0pt;
    line-height: 0.9083333333333333;
    text-align: left
}

.c141 {
    margin-left: 52.1pt;
    padding-top: 0pt;
    padding-left: 3.4pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c73 {
    margin-left: 52.1pt;
    padding-top: 0pt;
    padding-left: -0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c137 {
    margin-left: 52.1pt;
    padding-top: 0pt;
    padding-left: 3.1pt;
    padding-bottom: 0pt;
    line-height: 0.8708333333333332;
    text-align: justify
}

.c155 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify
}

.c31 {
    margin-left: 52.1pt;
    padding-top: 10.8pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c77 {
    margin-left: 34.1pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 2.0;
    text-align: justify;
    margin-right: 14.4pt
}

.c16 {
    margin-left: 52.1pt;
    padding-top: 0.1pt;
    padding-left: -0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c42 {
    margin-left: 52.1pt;
    padding-top: 10.9pt;
    padding-left: -0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c87 {
    margin-left: 34.1pt;
    padding-top: 0.2pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify;
    margin-right: 8.1pt
}

.c48 {
    margin-left: 52.1pt;
    padding-top: 0.1pt;
    padding-left: 10.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c15 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: left;
    margin-right: 6.2pt
}

.c149 {
    margin-left: 52.1pt;
    padding-top: 10.7pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 0.9083333333333333;
    text-align: left
}

.c150 {
    margin-left: 5.8pt;
    padding-top: 4.5pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.6pt
}

.c34 {
    margin-left: 52.1pt;
    padding-top: 10.5pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c133 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    text-indent: 28.3pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify
}

.c46 {
    margin-left: 52.1pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c154 {
    margin-left: 52.1pt;
    padding-top: 0pt;
    padding-left: -0.1pt;
    padding-bottom: 0pt;
    line-height: 0.9083333333333333;
    text-align: left
}

.c96 {
    margin-left: 5.8pt;
    padding-top: 4.5pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 9.5pt
}

.c69 {
    margin-left: 52.1pt;
    padding-top: 0.3pt;
    padding-left: 10.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c147 {
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 9pt
}

.c83 {
    margin-left: 52.1pt;
    padding-top: 0pt;
    padding-left: -0.1pt;
    padding-bottom: 0pt;
    line-height: 0.8958333333333334;
    text-align: justify
}

.c55 {
    margin-left: 52.1pt;
    padding-top: 0pt;
    padding-left: 10.3pt;
    padding-bottom: 0pt;
    line-height: 0.8708333333333332;
    text-align: left
}

.c23 {
    margin-left: 5.8pt;
    padding-top: 4.5pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify;
    margin-right: 8.8pt
}

.c95 {
    margin-left: 52.1pt;
    padding-top: 0.1pt;
    padding-left: 3.2pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c32 {
    margin-left: 52.1pt;
    padding-top: 0.1pt;
    padding-left: -0.1pt;
    padding-bottom: 0pt;
    line-height: 0.8958333333333334;
    text-align: justify
}

.c93 {
    margin-left: 52.1pt;
    padding-top: 0pt;
    padding-left: 10.1pt;
    padding-bottom: 0pt;
    line-height: 0.8958333333333334;
    text-align: justify
}

.c22 {
    margin-left: 5.8pt;
    padding-top: 4.5pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify;
    margin-right: 8.2pt
}

.c135 {
    margin-left: 52.1pt;
    padding-top: 0.1pt;
    padding-left: -0pt;
    padding-bottom: 0pt;
    line-height: 0.8958333333333334;
    text-align: justify
}

.c81 {
    margin-left: 52.1pt;
    padding-top: 0.3pt;
    padding-left: -0.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c76 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: left
}

.c119 {
    margin-left: 5.8pt;
    padding-top: 0.5pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c2 {
    padding-top: 0.6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    height: 11pt
}

.c5 {
    padding-top: 0.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    height: 11pt
}

.c134 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.8541666666666666;
    text-align: left
}

.c80 {
    padding-top: 0.5pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    height: 11pt
}

.c61 {
    margin-left: 34.1pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c111 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: center;
    margin-right: 3.1pt
}

.c106 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal
}

.c74 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.8708333333333332;
    text-align: left
}

.c153 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    padding-bottom: 0.5pt;
    line-height: 1.0;
    text-align: left
}

.c132 {
    margin-left: 5.8pt;
    padding-top: 4.5pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify
}

.c43 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    font-weight: 400;
    text-decoration: underline
}

.c90 {
    padding-top: 4.8pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    height: 11pt
}

.c70 {
    margin-left: 3.1pt;
    padding-top: 5.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c146 {
    margin-left: 3.4pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: left
}

.c156 {
    margin-left: 34.1pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.9083333333333333;
    text-align: left
}

.c36 {
    margin-left: 34.1pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.9;
    text-align: left
}

.c26 {
    margin-left: 3.1pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c116 {
    margin-left: 3.4pt;
    padding-top: 5.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c58 {
    padding-top: 8.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    height: 11pt
}

.c97 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.8458333333333333;
    text-align: left
}

.c75 {
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    height: 11pt
}

.c19 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c143 {
    margin-left: 5.8pt;
    padding-top: 4.5pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: left
}

.c105 {
    padding-top: 0.7pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    height: 11pt
}

.c38 {
    padding-top: 0.2pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    height: 11pt
}

.c39 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.875;
    text-align: left
}

.c129 {
    margin-left: 5.8pt;
    padding-top: 0.2pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify
}

.c127 {
    margin-left: 5.8pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c142 {
    margin-left: 5.8pt;
    padding-top: 0.3pt;
    padding-bottom: 0pt;
    line-height: 1.0083333333333333;
    text-align: justify
}

.c122 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.875;
    text-align: left;
    height: 11pt
}

.c125 {
    margin-left: 34.1pt;
    padding-top: 0.6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c131 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: justify
}

.c110 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c94 {
    padding-top: 0.4pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    height: 11pt
}

.c1 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.9166666666666666;
    text-align: left
}

.c101 {
    padding-top: 4.5pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c65 {
    margin-left: 6.8pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
}

.c40 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 20px;
}

.c102 {
    text-indent: 28.3pt;
    margin-right: 8.8pt
}

.c123 {
    text-indent: 28.3pt;
    margin-right: 8.3pt
}

.c86 {
    font-weight: 400;

}

.c115 {
    text-indent: 28.3pt;
    margin-right: 9.3pt
}

.c20 {
    color: inherit;
    text-decoration: inherit
}

.c21 {
    padding: 0;
    margin: 0
}

.c128 {
    margin-right: 8.2pt
}

.c145 {
    margin-right: 8.8pt
}

.c84 {
    height: 54.9pt
}

.c148 {
    height: 54.7pt
}

.c98 {
    font-size: 9pt
}

.c138 {
    margin-right: 11.2pt
}

.c33 {
    height: 22.1pt
}

.c71 {
    list-style-position: inside
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;

    line-height: 1.0;
    page-break-after: avoid;
    text-align: left
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
}

h1 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 9pt;
    padding-bottom: 0pt;

    line-height: 1.0;
    text-align: left
}

h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 9pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    line-height: 1.0;
    page-break-after: avoid;
    text-align: left
}

h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;

    line-height: 1.0;
    page-break-after: avoid;
    text-align: left
}

h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    line-height: 1.0;
    page-break-after: avoid;
    text-align: left
}

h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    line-height: 1.0;
    page-break-after: avoid;
    text-align: left
}
