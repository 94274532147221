ol {
    margin: 0;
    padding: 0
}

table td, table th {
    padding: 0
}

.c13 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 158.9pt;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c19 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 128.4pt;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c10 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 130.2pt;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c14 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 49.7pt;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c22 {
    padding-top: 0pt;
    text-indent: 35.5pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: center
}

.c0 {
    padding-top: 0pt;
    text-indent: 35.5pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;

    font-style: normal
}

.c29 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    text-indent: 35.5pt;
    padding-bottom: 0pt;
    line-height: 0.9166666666666666;
    text-align: left;
    margin-right: 6.1pt
}

.c25 {
    padding-top: 0pt;
    text-indent: 35.5pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: right
}

.c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;

    font-style: normal
}

.c8 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;

    font-style: normal
}

.c33 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;

    font-style: normal
}

.c34 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    text-indent: 35.5pt;
    padding-bottom: 0pt;
    line-height: 0.9166666666666666;
    text-align: left;
    margin-right: 22pt
}

.c27 {
    padding-top: 0pt;
    text-indent: 35.5pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c6 {
    margin-left: 3.1pt;
    padding-top: 0pt;
    text-indent: 35.5pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.c7 {
    margin-left: 5.8pt;
    padding-top: 0.5pt;
    text-indent: 35.5pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: right
}

.c38 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c16 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c15 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: left;
    margin-right: 6.7pt
}

.c12 {
    margin-left: 5.8pt;
    padding-top: 0pt;
    text-indent: 35.5pt;
    padding-bottom: 0.5pt;
    line-height: 1.0;
    text-align: left
}

.c31 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left;
    margin-right: 6.1pt
}

.c30 {
    margin-left: 3.4pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 0.9874999999999999;
    text-align: left;
    margin-right: 6.1pt
}

.c24 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.8458333333333333;
    text-align: left
}

.c36 {
    padding-top: 5.3pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-indent: 35.5pt;
    text-align: left
}

.c3 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.875;
    text-align: left
}

.c17 {
    padding-top: 0.4pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-indent: 35.5pt;
    text-align: left
}

.c35 {
    margin-left: 3.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.8541666666666666;
    text-align: left
}

.c32 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
}

.c9 {
    font-size: 9pt;

    font-weight: 700
}

.c18 {
    color: #0462c1;
    vertical-align: baseline;
    font-style: normal
}

.c20 {
    background-color: #ffffff;
    max-width: 467.7pt;
    padding: 20px;
}

.c11 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline
}

.c2 {
    font-size: 9pt;

    font-weight: 400
}

.c26 {
    color: inherit;
    text-decoration: inherit
}

.c23 {
    height: 22.1pt
}

.c4 {
    height: 11pt
}

.c21 {
    height: 54.7pt
}

.c37 {
    margin-left: 3.1pt
}

.c28 {
    height: 54.9pt
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;

    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;

}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;

}

h1 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 9pt;
    padding-bottom: 0pt;

    line-height: 1.0;
    text-align: justify
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;

    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;

    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;

    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;

    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;

    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}
