@charset "UTF-8";
@font-face {
    font-family: 'e-line-icons';
    src: url("./assets/fonts/e-line-icons.eot?gwcsk0");
    src: url("./assets/fonts/e-line-icons.eot?gwcsk0#iefix") format("embedded-opentype"), url("./assets/fonts/e-line-icons.ttf?gwcsk0") format("truetype"), url("./assets/fonts/e-line-icons.woff?gwcsk0") format("woff"), url("./assets/fonts/e-line-icons.svg?gwcsk0#e-line-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class*='icon-'] {
    font-family: 'e-line-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-lg {
    font-size: 1.33333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}

.icon-2x {
    font-size: 2em;
}

.icon-3x {
    font-size: 3em;
}

.icon-4x {
    font-size: 4em;
}

.icon-5x {
    font-size: 5em;
}

.icon-fw {
    width: 1.28571429em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}

.icon-ul > li {
    position: relative;
}

.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}

.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-border {
    padding: .2em .25em .15em;
    border: solid 0.08em #eeeeee;
    border-radius: .1em;
}

.icon-pull-left {
    float: left;
}

.icon-pull-right {
    float: right;
}

.icon.icon-pull-left {
    margin-right: .3em;
}

.icon.icon-pull-right {
    margin-left: .3em;
}

/* Deprecated as of 4.4.0 */
.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.icon.pull-left {
    margin-right: .3em;
}

.icon.pull-right {
    margin-left: .3em;
}

.icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
}

.icon-pulse {
    -webkit-animation: icon-spin 1s infinite steps(8);
    animation: icon-spin 1s infinite steps(8);
}

@-webkit-keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.icon-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.icon-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.icon-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.icon-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.icon-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}

:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
    filter: none;
}

.icon-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
}

.icon-stack-1x,
.icon-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
}

.icon-stack-1x {
    line-height: inherit;
}

.icon-stack-2x {
    font-size: 2em;
}

.icon-inverse {
    color: #ffffff;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}

.icon-cloud:before {
    content: "";
}

.icon-ATM:before {
    content: "";
}

.icon-layers:before {
    content: "";
}

.icon-eye-invisible:before {
    content: "";
}

.icon-air:before {
    content: "";
}

.icon-area-graph:before {
    content: "";
}

.icon-bell:before {
    content: "";
}

.icon-code:before {
    content: "";
}

.icon-gauge:before {
    content: "";
}

.icon-key:before {
    content: "";
}

.icon-lifebuoy:before {
    content: "";
}

.icon-location-pin:before {
    content: "";
}

.icon-mail:before {
    content: "";
}

.icon-shield:before {
    content: "";
}

.icon-suitcase:before {
    content: "";
}

.icon-car:before {
    content: "";
}

.icon-ccs-type1:before {
    content: "";
}

.icon-ccs:before {
    content: "";
}

.icon-chademo:before {
    content: "";
}

.icon-type2:before {
    content: "";
}

.icon-3pin-adapter:before {
    content: "";
}

.icon-gbt:before {
    content: "";
}

.icon-type1:before {
    content: "";
}

.icon-schuko:before {
    content: "";
}

.icon-europlug:before {
    content: "";
}

.icon-fast-charge:before {
    content: "";
}

.icon-map:before {
    content: "";
}

.icon-protection:before {
    content: "";
}

.icon-short-circuit-g:before {
    content: "";
}

.icon-stations:before {
    content: "";
}

.icon-all-stations:before {
    content: "";
}

.icon-station-d:before {
    content: "";
}

.icon-station-a:before {
    content: "";
}

.icon-station-v:before {
    content: "";
}

.icon-station-c:before {
    content: "";
}

.icon-station-m:before {
    content: "";
}

.icon-station-h:before {
    content: "";
}

.icon-station-f:before {
    content: "";
}

.icon-guest-charge:before {
    content: "";
}

.icon-station-partner:before {
    content: "";
}

.icon-station-status:before {
    content: "";
}

.icon-station-free:before {
    content: "";
}

.icon-station-j:before {
    content: "";
}

.icon-station-p:before {
    content: "";
}

.icon-station-t:before {
    content: "";
}

.icon-price-ribbon:before {
    content: "";
}

.icon-e-line-logo:before {
    content: "";
}

.icon-e-line:before {
    content: "";
}

.icon-tools:before {
    content: "";
}

.icon-do-not-disturb:before {
    content: "";
}

.icon-gas-station:before {
    content: "";
}

.icon-parking-place-l:before {
    content: "";
}

.icon-parking-sign:before {
    content: "";
}

.icon-restaurant:before {
    content: "";
}

.icon-shopping-cart:before {
    content: "";
}

.icon-bricks:before {
    content: "";
}

.icon-users-l:before {
    content: "";
}

.icon-reservation:before {
    content: "";
}

.icon-achievement:before {
    content: "";
}

.icon-add-user:before {
    content: "";
}

.icon-alarm:before {
    content: "";
}

.icon-arrow-down:before {
    content: "";
}

.icon-arrow-left:before {
    content: "";
}

.icon-arrow-right:before {
    content: "";
}

.icon-arrow-up:before {
    content: "";
}

.icon-atm:before {
    content: "";
}

.icon-balance:before {
    content: "";
}

.icon-bars-extended:before {
    content: "";
}

.icon-bars-narrowed:before {
    content: "";
}

.icon-battery:before {
    content: "";
}

.icon-bookmark:before {
    content: "";
}

.icon-business:before {
    content: "";
}

.icon-business-name:before {
    content: "";
}

.icon-cafe:before {
    content: "";
}

.icon-calendar:before {
    content: "";
}

.icon-car-service:before {
    content: "";
}

.icon-card:before {
    content: "";
}

.icon-cards:before {
    content: "";
}

.icon-charge:before {
    content: "";
}

.icon-charges:before {
    content: "";
}

.icon-check:before {
    content: "";
}

.icon-check-circle:before {
    content: "";
}

.icon-chevron-down:before {
    content: "";
}

.icon-chevron-left:before {
    content: "";
}

.icon-chevron-right:before {
    content: "";
}

.icon-chevron-up:before {
    content: "";
}

.icon-clapperboard:before {
    content: "";
}

.icon-close:before {
    content: "";
}

.icon-close-circle:before {
    content: "";
}

.icon-dashboard:before {
    content: "";
}

.icon-description:before {
    content: "";
}

.icon-download:before {
    content: "";
}

.icon-edit:before {
    content: "";
}

.icon-electricity:before {
    content: "";
}

.icon-energy:before {
    content: "";
}

.icon-error:before {
    content: "";
}

.icon-eye-invisible1:before {
    content: "";
}

.icon-eye-visible:before {
    content: "";
}

.icon-facebook:before {
    content: "";
}

.icon-filter:before {
    content: "";
}

.icon-fuel:before {
    content: "";
}

.icon-google:before {
    content: "";
}

.icon-gps:before {
    content: "";
}

.icon-help:before {
    content: "";
}

.icon-help-circle:before {
    content: "";
}

.icon-home:before {
    content: "";
}

.icon-info:before {
    content: "";
}

.icon-instagram:before {
    content: "";
}

.icon-language:before {
    content: "";
}

.icon-leaf:before {
    content: "";
}

.icon-line-graph:before {
    content: "";
}

.icon-link:before {
    content: "";
}

.icon-loader:before {
    content: "";
}

.icon-location:before {
    content: "";
}

.icon-locked:before {
    content: "";
}

.icon-login:before {
    content: "";
}

.icon-logout:before {
    content: "";
}

.icon-manually:before {
    content: "";
}

.icon-map1:before {
    content: "";
}

.icon-medal:before {
    content: "";
}

.icon-minus:before {
    content: "";
}

.icon-minus-circle:before {
    content: "";
}

.icon-money:before {
    content: "";
}

.icon-notification:before {
    content: "";
}

.icon-parking:before {
    content: "";
}

.icon-pharmacy:before {
    content: "";
}

.icon-phone:before {
    content: "";
}

.icon-pin:before {
    content: "";
}

.icon-plus:before {
    content: "";
}

.icon-plus-circle:before {
    content: "";
}

.icon-print:before {
    content: "";
}

.icon-reservations:before {
    content: "";
}

.icon-route:before {
    content: "";
}

.icon-search:before {
    content: "";
}

.icon-services:before {
    content: "";
}

.icon-settings:before {
    content: "";
}

.icon-share:before {
    content: "";
}

.icon-shop:before {
    content: "";
}

.icon-shopping-basket:before {
    content: "";
}

.icon-signal:before {
    content: "";
}

.icon-sort-down:before {
    content: "";
}

.icon-sort-up:before {
    content: "";
}

.icon-star:before {
    content: "";
}

.icon-star-o:before {
    content: "";
}

.icon-station-location:before {
    content: "";
}

.icon-statistic:before {
    content: "";
}

.icon-stopwatch:before {
    content: "";
}

.icon-time:before {
    content: "";
}

.icon-time-slot:before {
    content: "";
}

.icon-twitter:before {
    content: "";
}

.icon-unlocked:before {
    content: "";
}

.icon-upload:before {
    content: "";
}

.icon-user:before {
    content: "";
}

.icon-user-info:before {
    content: "";
}

.icon-user-profile:before {
    content: "";
}

.icon-users:before {
    content: "";
}

.icon-warning:before {
    content: "";
}

.icon-wc:before {
    content: "";
}

html, body {
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    height: 100vh;
    margin: 0;
    padding: 0;
}

button {
    font-family: "Montserrat", sans-serif;
    outline: none;
    box-shadow: 0 1px 8px 0 rgba(26, 26, 26, 0.2);
}

.btn-next {
    border-radius: 5px;
    box-shadow: 0 1px 8px 0 rgba(26, 26, 26, 0.2);
    background-color: #FFF300;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    font-size: 1.75rem;
    font-weight: bold;
    color: #101010;
    padding: 1rem 3rem 1rem 1rem;
    z-index: 99;
}

.btn-next:after {
    content: '〉';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.battery {
    border-radius: 8px;
    background-color: #101010;
    height: 6rem;
    width: 100%;
    max-width: 400px;
    margin: 1rem 3rem 1rem 2rem;
    position: relative;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
}

.battery:after {
    content: '';
    width: 10px;
    height: 30px;
    position: absolute;
    right: -15px;
    top: calc(50% - 15px);
    background: #FFF300;
    border-radius: 4px 8px 8px 4px;
}

.battery .empty {
    height: 100%;
    background: #ffffff;
    border-radius: 8px;
    opacity: 0.9;
}

.battery .percent {
    position: absolute;
    top: calc(50% - 2rem);
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 3.125rem;
    font-weight: 800;
    color: white;
    mix-blend-mode: difference;
}

.battery .percent:after {
    content: '%';
    font-size: 1.5rem;
}

.icon {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.icon.type2 {
    background-image: url("./assets/connectors/type2.svg");
}

.icon.schuko {
    background-image: url("./assets/connectors/schuko.svg");
}

.icon.ccs {
    background-image: url("./assets/connectors/ccs.svg");
}

.icon.chademo {
    background-image: url("./assets/connectors/chademo.svg");
}

@media (max-width: 320px) {
    html, body {
        font-size: 12px !important;
    }
}

@media (min-width: 321px) and (max-width: 767px) {
    html, body {
        font-size: 15px !important;
    }
}

@media (min-width: 768px) {
    html, body {
        font-size: 16px !important;
    }
}

html, body, #root, main {
    height: 100%;
}

main {
    color: #ffffff;
    display: flex;
    flex-direction: column;
}
