ol {
  margin: 0;
  padding: 0
}

table td, table th {
  padding: 0
}

.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;

  font-style: normal
}

.c6 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: center
}

.c1 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left
}

.c0 {
  font-size: 12pt;
  font-weight: 400
}

.c7 {
  background-color: #ffffff;
  max-width: 481.6pt;
  padding: 20px
}

.c3 {
  font-size: 12pt;
  font-weight: 700
}

.c5 {
  color: #000000
}

.c4 {
  height: 10pt
}

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;

  line-height: 1.0;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  line-height: 1.0;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left
}

li {
  color: #000000;
  font-size: 10pt;
}

p {
  margin: 0;
  color: #000000;
  font-size: 10pt;
}

h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;

  line-height: 1.0;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;

  line-height: 1.0;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;

  line-height: 1.0;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;

  line-height: 1.0;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;

  line-height: 1.0;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;

  line-height: 1.0;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}
